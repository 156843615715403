<template>
    <v-container fluid class="pt-0 mt-0">
        <HeaderAzul :titulo="aula.curso" :subtitulo="aula.turma" :porcento="aula.aproveitamento_porcentagem"/>
        <v-row class="justify-center mt-3">
            <v-col cols="12">
                <h2 style="color: #005FAB; font-size: 25px;" class="justify-center mt-3">Aula {{aula.aula_numero}} | {{dataPt(aula.datahora_inicio).split(' ')[1]}}</h2>
            </v-col>
            <v-col v-for="(c, i) in aula.conteudo" cols="12" md="6" lg="4" xl="3" :key="i">
                <v-badge style="width: 100%;" :color="c.visto == 't' ? '#005FAB' : '#FF5722'" :icon="c.visto == 't' ? 'mdi-check':'mdi-close'" offset-x="12" offset-y="12">
                    <v-card :to="`/curso/aula/conteudo/${id_aula}/${c.id_conteudo}`"><v-card-text class="pt-3 my-0"><h2>{{c.conteudo}}</h2></v-card-text></v-card>
                </v-badge>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

import axios from "axios"
import {mapState} from "vuex"
import HeaderAzul from "@/Views/Estrutura/HeaderAzul";

export default {
    name: "CursoAula",
    components: {HeaderAzul},
    props : ["id_aula"],
    data: () => ({
        aula : {}
    }),
    computed : {
        ...mapState(['baseUrl'])
    },
    methods : {
        getAula() {
            return axios.post(`${this.baseUrl}aula/aluno_get`, {id_aula : this.id_aula}).then( (res) => {
                this.aula = res.data
            })
        },
    },
    activated() {
        this.getAula()
    }
}
</script>

<style scoped>
    h2 {font-size: 19px; font-weight: normal; text-align: center; line-height: 110%; color: #00436F;}
    h2 strong {font-weight: bold;}
</style>